<script setup lang="ts">
import { OpenAPI } from "./api.generated/scion";
import { OpenAPI as SpendOpenAPI } from "@payout-link/api.generated/scion";
import { SEND_TRANSLATION } from "@payout-link/i18n-messages";

OpenAPI.BASE = (import.meta.env.VITE_SCION_BASE_URL as string).split(",")[0];
SpendOpenAPI.BASE = (import.meta.env.VITE_SCION_BASE_URL as string).split(
  ","
)[0];
SEND_TRANSLATION.URL = import.meta.env.VITE_SPEND_TRANSLATION_URL as string;
</script>

<template>
  <router-view />
</template>

<style scoped></style>
