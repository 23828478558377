import { App } from "vue";
import { Router } from "vue-router";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

// Attach sentry to the given app/router
export function initSentry({
  app,
  router,
  dsn,
  environment,
}: {
  app: App;
  router: Router;
  dsn: string;
  environment: string;
}) {
  Sentry.init({
    app,
    dsn: dsn,
    environment: getSentryEnvironment(environment),
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [import.meta.env.VITE_SPEND_BASE_URL, /^\//],
      }) as any,
      new Sentry.Replay({
        // Tweak masking parameters
        // Instead of masking all input we explicitly mask fields with PII
        // See https://docs.sentry.io/platforms/javascript/session-replay/privacy/
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
    // Sentry hides errors from console by default, don't
    logErrors: true,
    ignoreErrors: [
      // This exception is specific to a project where Chromium is embedded in C# and should therefore be relatively
      // safe to ignore without losing actual errors. We are ignoring it to avoid repeating event flooding
      "Non-Error promise rejection captured with value: Object Not Found Matching Id:",
      // Fixing error flooding issue associated with Sentry:
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
    ],
    replaysOnErrorSampleRate: 1.0,
  });
}

export function initSentryFromEnv({
  app,
  router,
}: {
  app: App;
  router: Router;
}) {
  const dsn = import.meta.env.VITE_SENTRY_DSN;
  const environment = import.meta.env.VITE_SENTRY_ENVIRONMENT;
  if (!dsn) {
    console.debug("Not installing sentry as missing DSN");
    return;
  }
  if (!environment) {
    console.debug("Not installing sentry as missing environment");
    return;
  }
  initSentry({ app, router, dsn, environment });
}

function getSentryEnvironment(environment: string): string {
  const is_pull_request = import.meta.env.VITE_PULL_REQUEST;
  if (is_pull_request === "true") {
    // Deploy previews don't count as errors on a particular environment
    return "PREVIEW";
  }
  return environment;
}
