<script setup lang="ts">
import { OpenAPI } from "../../api.generated/scion";
import { LoadingSpinner } from "@wegift/two-components";
import { useRoute, useRouter } from "vue-router";
import { useLinkDetailsStore } from "../../stores/linkDetails";
import { useProductStore } from "../../stores/product";
import {
  getSelectLinkDetails,
  getSelectLinkProducts,
} from "../../services/selectLink";
import { onMounted, ref } from "vue";
import { RouteNames } from "../../utils/routes";
import { useTokenStore } from "../../stores/token";

const route = useRoute();
const router = useRouter();
const linkDetailsStore = useLinkDetailsStore();
const productStore = useProductStore();
const hasLoaded = ref<boolean>(false);
const tokenStore = useTokenStore();

const token = route.params.token as string;
const exp = ref<string | null>((route?.query?.exp as string) || null);
const routeName = route.name;

async function loadLinkDetails() {
  const selectLinkDetails = await getSelectLinkDetails(token, exp.value);
  selectLinkDetails.logoUrl =
    "https://connect.dev.runa.io/files/f/UF-XNWWBEBX/vertical_logo.png";
  linkDetailsStore.setLinkDetails(selectLinkDetails);
  productStore.setCategories(selectLinkDetails.productCategories);
}

function loadAvailableProducts() {
  const selectLinkProducts = getSelectLinkProducts(token);
  productStore.setProducts(selectLinkProducts.products);
}

async function getLinkDetailsAndProducts(isRetry?: boolean) {
  try {
    await Promise.all([loadLinkDetails()]);
    loadAvailableProducts();
    tokenStore.setToken(token);

    if (linkDetailsStore.hasBeenClicked && !routeName) {
      router.push({ name: RouteNames.Home, query: route.query });
    } else if (!linkDetailsStore.hasBeenClicked) {
      router.push({ name: RouteNames.Onboarding, query: route.query });
    }
    hasLoaded.value = true;
  } catch (e) {
    const baseUrl = import.meta.env.VITE_SCION_ALTERNATIVE_BASE_URL as string;

    if (baseUrl && !isRetry) {
      OpenAPI.BASE = baseUrl;
      getLinkDetailsAndProducts(true);
    } else {
      console.error(e);
      router.push({ name: RouteNames.Error });
    }
  }
}

onMounted(async () => {
  if (routeName !== RouteNames.Error) {
    getLinkDetailsAndProducts();
  }
});
</script>

<template>
  <div v-if="!hasLoaded" class="absolute inset-0 flex bg-white">
    <LoadingSpinner class="m-auto flex-1" />
  </div>
  <router-view v-else></router-view>
</template>
